import React from "react";

export default function NotFound() {
    return (
        <div
            className="w-full h-full flex justify-center items-center 
                        border-"
        >
            <p className="text-5xl">404 Page Not Found, lol</p>
        </div>
    );
}
